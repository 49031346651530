<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M15.419,23.25H8.761a1.5,1.5,0,0,1-1.455-1.136L3.974,8.788a1.5,1.5,0,0,1,.114-1.034L7.175,1.579A1.5,1.5,0,0,1,8.517.75h7.146A1.5,1.5,0,0,1,17,1.579l3.088,6.175A1.5,1.5,0,0,1,20.2,8.788L16.874,22.114A1.5,1.5,0,0,1,15.419,23.25Z"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="12.09"
      y1="5.25"
      x2="12.09"
      y2="16.5"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="9.09"
      y1="8.25"
      x2="15.09"
      y2="8.25"
    />
  </svg>
</template>
